import * as React from "react";

const SvgLogo = (props) => (
<svg width={392} height={70} viewBox="0 0 566.244 137.583" {...props}>
  <g transform="translate(234.953 -8.19)">
    <g
      ariaLabel="Hub"
      style={{ lineHeight: "1.25", InkscapeFontSpecification: "Ubuntu" }}
      fill="#05aca2"
      fillOpacity="1"
      stroke="#05aca2"
      strokeDasharray="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="4"
      strokeOpacity="1"
      strokeWidth="5.021"
      fontFamily="Ubuntu"
      fontSize="70.583"
      fontStretch="normal"
      fontStyle="normal"
      fontVariant="normal"
      fontWeight="normal"
      letterSpacing="0"
      paintOrder="markers fill stroke"
      wordSpacing="0"
    >
      <g
        fillOpacity="1"
        strokeDasharray="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="1"
      >
        <path
          fill="#462066"
          fillRule="nonzero"
          stroke="#462066"
          strokeMiterlimit="16.9"
          strokeWidth="4.961"
          d="M-173.535 10.671l-58.914 31.115-.024 67.949 56.508 33.558 56.037-34.44.02-.44V61.415l-19.476 13.2v22.843h0l-36.755 22.634-36.469-21.658.018-44.673 38.487-20.327.587.348v.002l17.578 10.44-45.384 27.689h0l25.23 28.652v-21.65l56.38-36.277z"
          opacity="1"
          paintOrder="stroke markers fill"
        />
        <g
          strokeMiterlimit="4"
          strokeWidth="5.021"
          fontFamily="Ubuntu"
          fontSize="89.777"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          paintOrder="markers fill stroke"
        >
          <g
            ariaLabel="Eduka"
            style={{
              lineHeight: "1.25",
              InkscapeFontSpecification: "Ubuntu",
            }}
            fill="#462066"
            stroke="#462066"
            letterSpacing="0"
            wordSpacing="0"
          >
            <path
              d="M-86.394 109.15V46.936h37.975v7.452h-29.267V72.88h26.035v7.272h-26.035v21.546h31.512v7.452z"
              style={{ InkscapeFontSpecification: "Ubuntu" }}
            />
            <path
              d="M-7.032 72.342q-1.526-1.257-4.399-2.424-2.872-1.167-6.284-1.167-3.591 0-6.195 1.347-2.513 1.257-4.13 3.591-1.615 2.244-2.334 5.387-.718 3.142-.718 6.733 0 8.17 4.04 12.658 4.04 4.4 10.773 4.4 3.412 0 5.656-.27 2.335-.359 3.591-.718zm0-31.422l8.35-1.436v68.32q-2.873.808-7.362 1.616t-10.324.808q-5.387 0-9.696-1.706-4.31-1.705-7.362-4.848-3.052-3.142-4.758-7.63-1.616-4.58-1.616-10.235 0-5.387 1.347-9.876 1.436-4.488 4.13-7.72 2.693-3.232 6.553-5.028 3.95-1.795 8.978-1.795 4.04 0 7.092 1.077 3.142 1.077 4.668 2.065z"
              style={{ InkscapeFontSpecification: "Ubuntu" }}
            />
            <path
              d="M52.85 107.804q-2.873.718-7.632 1.526-4.668.808-10.863.808-5.386 0-9.067-1.526-3.68-1.616-5.925-4.489-2.245-2.873-3.232-6.733-.988-3.95-.988-8.708V62.467h8.35v24.42q0 8.528 2.693 12.209 2.693 3.68 9.067 3.68 1.347 0 2.783-.09 1.437-.089 2.694-.179 1.256-.18 2.244-.269 1.077-.18 1.526-.36V62.468h8.35z"
              style={{ InkscapeFontSpecification: "Ubuntu" }}
            />
            <path
              d="M84.092 83.475q2.693 2.064 5.656 5.117 3.052 3.052 5.925 6.643 2.963 3.502 5.566 7.183 2.604 3.59 4.4 6.733h-9.786q-1.886-3.142-4.4-6.374-2.424-3.322-5.117-6.285-2.693-3.052-5.476-5.566t-5.297-4.22v22.445h-8.35V40.92l8.35-1.437v42.554l4.758-4.758q2.604-2.603 5.117-5.297 2.514-2.693 4.759-5.117 2.244-2.514 3.86-4.4h9.786q-2.065 2.245-4.49 4.939-2.423 2.693-5.027 5.476-2.603 2.693-5.297 5.476-2.603 2.694-4.937 5.118z"
              style={{ InkscapeFontSpecification: "Ubuntu" }}
            />
            <path
              d="M129.878 103.136q2.963 0 5.207-.09 2.334-.18 3.86-.539V88.592q-.897-.449-2.962-.718-1.975-.36-4.848-.36-1.886 0-4.04.27-2.065.27-3.86 1.167-1.706.808-2.873 2.334-1.168 1.437-1.168 3.86 0 4.49 2.873 6.285 2.873 1.706 7.81 1.706zM129.16 61.3q5.027 0 8.439 1.346 3.501 1.257 5.566 3.681 2.155 2.334 3.052 5.656.898 3.232.898 7.182v29.178q-1.077.18-3.052.538-1.886.27-4.31.54-2.424.268-5.296.448-2.784.27-5.567.27-3.95 0-7.272-.809-3.321-.808-5.745-2.513-2.424-1.796-3.77-4.669-1.348-2.873-1.348-6.913 0-3.86 1.527-6.643 1.616-2.783 4.309-4.489t6.284-2.514q3.591-.808 7.542-.808 1.256 0 2.603.18 1.347.09 2.514.359 1.257.18 2.154.36.898.179 1.257.268v-2.334q0-2.065-.449-4.04-.448-2.065-1.616-3.59-1.167-1.617-3.231-2.515-1.976-.987-5.208-.987-4.13 0-7.271.628-3.053.539-4.58 1.167l-.987-6.912q1.616-.719 5.387-1.347 3.77-.718 8.17-.718z"
              style={{ InkscapeFontSpecification: "Ubuntu" }}
            />
          </g>
          <path
            d="M212.52 46.935h8.708v62.216h-8.709V80.422h-30.434v28.729h-8.708V46.935h8.708v25.946h30.434z"
            style={{ InkscapeFontSpecification: "Ubuntu" }}
            fill="#05aca2"
            stroke="#05aca2"
          />
          <path
            d="M273.298 107.804q-2.873.718-7.63 1.526-4.67.808-10.864.808-5.386 0-9.067-1.526-3.681-1.616-5.925-4.489-2.245-2.873-3.232-6.733-.988-3.95-.988-8.708V62.467h8.35v24.42q0 8.528 2.693 12.209 2.693 3.68 9.067 3.68 1.347 0 2.783-.09 1.437-.089 2.693-.179 1.257-.18 2.245-.269 1.077-.18 1.526-.36V62.468h8.35z"
            style={{ InkscapeFontSpecification: "Ubuntu" }}
            fill="#05aca2"
            stroke="#05aca2"
          />
          <path
            d="M296.012 64.532q1.526-.988 4.578-2.065 3.143-1.077 7.183-1.077 5.027 0 8.887 1.795 3.95 1.796 6.644 5.028 2.693 3.232 4.04 7.72 1.436 4.49 1.436 9.876 0 5.656-1.705 10.234-1.616 4.49-4.669 7.631-3.052 3.143-7.362 4.848-4.309 1.706-9.695 1.706-5.836 0-10.325-.808-4.489-.808-7.361-1.616V40.92l8.349-1.436zm0 37.347q1.257.359 3.501.718 2.334.27 5.746.27 6.733 0 10.773-4.4 4.04-4.488 4.04-12.658 0-3.591-.718-6.733-.718-3.143-2.334-5.387-1.616-2.334-4.22-3.591-2.514-1.347-6.105-1.347-3.411 0-6.284 1.167-2.873 1.167-4.4 2.424z"
            style={{ InkscapeFontSpecification: "Ubuntu" }}
            fill="#05aca2"
            stroke="#05aca2"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
);
export default SvgLogo;
